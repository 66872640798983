<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <form @submit.prevent="greet">
      <InputText type="text" v-model="text" />
      <Button type="submit" label="Submit" />
      <h3>{{ message }}</h3>
    </form>
    <div>
      <Toast />

      <h5>Basic</h5>
      <SplitButton
        label="Save"
        icon="pi pi-plus"
        @click="save"
        :model="items"
      ></SplitButton>

      <h5>Nested</h5>
      <SplitButton
        label="Save"
        icon="pi pi-plus"
        @click="save"
        :model="nestedItems"
      ></SplitButton>

      <h5>Severities</h5>
      <SplitButton label="Primary" :model="items" class="mb-2"></SplitButton>
      <SplitButton
        label="Secondary"
        :model="items"
        class="p-button-secondary mb-2"
      ></SplitButton>
      <SplitButton
        label="Success"
        :model="items"
        class="p-button-success mb-2"
      ></SplitButton>
      <SplitButton
        label="Info"
        :model="items"
        class="p-button-info mb-2"
      ></SplitButton>
      <SplitButton
        label="Warning"
        :model="items"
        class="p-button-warning mb-2"
      ></SplitButton>
      <SplitButton
        label="Help"
        :model="items"
        class="p-button-help mb-2"
      ></SplitButton>
      <SplitButton
        label="Danger"
        :model="items"
        class="p-button-danger mb-2"
      ></SplitButton>

      <h5>Raised Buttons</h5>
      <SplitButton
        label="Primary"
        :model="items"
        class="p-button-raised mb-2"
      ></SplitButton>
      <SplitButton
        label="Secondary"
        :model="items"
        class="p-button-raised p-button-secondary mb-2"
      ></SplitButton>
      <SplitButton
        label="Success"
        :model="items"
        class="p-button-raised p-button-success mb-2"
      ></SplitButton>
      <SplitButton
        label="Info"
        :model="items"
        class="p-button-raised p-button-info mb-2"
      ></SplitButton>
      <SplitButton
        label="Warning"
        :model="items"
        class="p-button-raised p-button-warning mb-2"
      ></SplitButton>
      <SplitButton
        label="Help"
        :model="items"
        class="p-button-raised p-button-help mb-2"
      ></SplitButton>
      <SplitButton
        label="Danger"
        :model="items"
        class="p-button-raised p-button-danger mb-2"
      ></SplitButton>

      <h5>Rounded Buttons</h5>
      <SplitButton
        label="Primary"
        :model="items"
        class="p-button-rounded mb-2"
      ></SplitButton>
      <SplitButton
        label="Secondary"
        :model="items"
        class="p-button-rounded p-button-secondary mb-2"
      ></SplitButton>
      <SplitButton
        label="Success"
        :model="items"
        class="p-button-rounded p-button-success mb-2"
      ></SplitButton>
      <SplitButton
        label="Info"
        :model="items"
        class="p-button-rounded p-button-info mb-2"
      ></SplitButton>
      <SplitButton
        label="Warning"
        :model="items"
        class="p-button-rounded p-button-warning mb-2"
      ></SplitButton>
      <SplitButton
        label="Help"
        :model="items"
        class="p-button-rounded p-button-help mb-2"
      ></SplitButton>
      <SplitButton
        label="Danger"
        :model="items"
        class="p-button-rounded p-button-danger mb-2"
      ></SplitButton>

      <h5>Text Buttons</h5>
      <SplitButton
        label="Primary"
        :model="items"
        class="p-button-text mb-2"
      ></SplitButton>
      <SplitButton
        label="Secondary"
        :model="items"
        class="p-button-text p-button-secondary mb-2"
      ></SplitButton>
      <SplitButton
        label="Success"
        :model="items"
        class="p-button-text p-button-success mb-2"
      ></SplitButton>
      <SplitButton
        label="Info"
        :model="items"
        class="p-button-text p-button-info mb-2"
      ></SplitButton>
      <SplitButton
        label="Warning"
        :model="items"
        class="p-button-text p-button-warning mb-2"
      ></SplitButton>
      <SplitButton
        label="Help"
        :model="items"
        class="p-button-text p-button-help mb-2"
      ></SplitButton>
      <SplitButton
        label="Danger"
        :model="items"
        class="p-button-text p-button-danger mb-2"
      ></SplitButton>

      <h5>Raised Text Buttons</h5>
      <SplitButton
        label="Primary"
        :model="items"
        class="p-button-raised p-button-text mb-2"
      ></SplitButton>
      <SplitButton
        label="Secondary"
        :model="items"
        class="p-button-raised p-button-text p-button-secondary mb-2"
      ></SplitButton>
      <SplitButton
        label="Success"
        :model="items"
        class="p-button-raised p-button-text p-button-success mb-2"
      ></SplitButton>
      <SplitButton
        label="Info"
        :model="items"
        class="p-button-raised p-button-text p-button-info mb-2"
      ></SplitButton>
      <SplitButton
        label="Warning"
        :model="items"
        class="p-button-raised p-button-text p-button-warning mb-2"
      ></SplitButton>
      <SplitButton
        label="Help"
        :model="items"
        class="p-button-raised p-button-text p-button-help mb-2"
      ></SplitButton>
      <SplitButton
        label="Danger"
        :model="items"
        class="p-button-raised p-button-text p-button-danger mb-2"
      ></SplitButton>

      <h5>Outlined Buttons</h5>
      <SplitButton
        label="Primary"
        :model="items"
        class="p-button-outlined mb-2"
      ></SplitButton>
      <SplitButton
        label="Secondary"
        :model="items"
        class="p-button-outlined p-button-secondary mb-2"
      ></SplitButton>
      <SplitButton
        label="Success"
        :model="items"
        class="p-button-outlined p-button-success mb-2"
      ></SplitButton>
      <SplitButton
        label="Info"
        :model="items"
        class="p-button-outlined p-button-info mb-2"
      ></SplitButton>
      <SplitButton
        label="Warning"
        :model="items"
        class="p-button-outlined p-button-warning mb-2"
      ></SplitButton>
      <SplitButton
        label="Help"
        :model="items"
        class="p-button-outlined p-button-help mb-2"
      ></SplitButton>
      <SplitButton
        label="Danger"
        :model="items"
        class="p-button-outlined p-button-danger mb-2"
      ></SplitButton>

      <h5>Sizes</h5>
      <SplitButton
        label="Small"
        :model="items"
        class="p-button-sm mb-2"
      ></SplitButton>
      <SplitButton label="Normal" :model="items" class="mb-2"></SplitButton>
      <SplitButton
        label="Large"
        :model="items"
        class="p-button-lg mb-2"
      ></SplitButton>

      <h5>Templating</h5>
      <SplitButton :model="items" class="bg-primary border-round">
        <Button @click="save">
          <img
            alt="logo"
            src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
            style="width: 1rem"
          />
          <span class="ml-2 flex align-items-center font-bold">PrimeVue</span>
        </Button>
      </SplitButton>
    </div>
    <div class="form-demo">
      <Dialog
        v-model:visible="showMessage"
        :breakpoints="{ '960px': '80vw' }"
        :style="{ width: '30vw' }"
        position="top"
      >
        <div class="flex align-items-center flex-column pt-6 px-3">
          <i
            class="pi pi-check-circle"
            :style="{ fontSize: '5rem', color: 'var(--green-500)' }"
          ></i>
          <h5>Registration Successful!</h5>
          <p :style="{ lineHeight: 1.5, textIndent: '1rem' }">
            Your account is registered under name <b>{{ state.name }}</b> ;
            it'll be valid next 30 days without activation. Please check
            <b>{{ state.email }}</b> for activation instructions.
          </p>
        </div>
        <template #footer>
          <div class="flex justify-content-center">
            <Button label="OK" @click="toggleDialog" class="p-button-text" />
          </div>
        </template>
      </Dialog>

      <div class="flex justify-content-center">
        <div class="card">
          <h5 class="text-center">Register</h5>
          <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="field">
              <div class="p-float-label">
                <InputText
                  id="name"
                  v-model="v$.name.$model"
                  :class="{ 'p-invalid': v$.name.$invalid && submitted }"
                />
                <label
                  for="name"
                  :class="{ 'p-error': v$.name.$invalid && submitted }"
                  >Name*</label
                >
              </div>
              <small
                v-if="
                  (v$.name.$invalid && submitted) || v$.name.$pending.$response
                "
                class="p-error"
                >{{ v$.name.required.$message.replace("Value", "Name") }}</small
              >
            </div>
            <div class="field">
              <div class="p-float-label p-input-icon-right">
                <i class="pi pi-envelope" />
                <InputText
                  id="email"
                  v-model="v$.email.$model"
                  :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                  aria-describedby="email-error"
                />
                <label
                  for="email"
                  :class="{ 'p-error': v$.email.$invalid && submitted }"
                  >Email*</label
                >
              </div>
              <span v-if="v$.email.$error && submitted">
                <span
                  id="email-error"
                  v-for="(error, index) of v$.email.$errors"
                  :key="index"
                >
                  <small class="p-error">{{ error.$message }}</small>
                </span>
              </span>
              <small
                v-else-if="
                  (v$.email.$invalid && submitted) ||
                  v$.email.$pending.$response
                "
                class="p-error"
                >{{
                  v$.email.required.$message.replace("Value", "Email")
                }}</small
              >
            </div>
            <div class="field">
              <div class="p-float-label">
                <Password
                  id="password"
                  v-model="v$.password.$model"
                  :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                  toggleMask
                >
                  <template #header>
                    <h6>Pick a password</h6>
                  </template>
                  <template #footer="sp">
                    {{ sp.level }}
                    <Divider />
                    <p class="mt-2">Suggestions</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                      <li>At least one lowercase</li>
                      <li>At least one uppercase</li>
                      <li>At least one numeric</li>
                      <li>Minimum 8 characters</li>
                    </ul>
                  </template>
                </Password>
                <label
                  for="password"
                  :class="{ 'p-error': v$.password.$invalid && submitted }"
                  >Password*</label
                >
              </div>
              <small
                v-if="
                  (v$.password.$invalid && submitted) ||
                  v$.password.$pending.$response
                "
                class="p-error"
                >{{
                  v$.password.required.$message.replace("Value", "Password")
                }}</small
              >
            </div>
            <div class="field">
              <div class="p-float-label">
                <Calendar id="date" v-model="date" :showIcon="true" />
                <label for="date">Birthday</label>
              </div>
            </div>
            <div class="field-checkbox">
              <Checkbox
                id="accept"
                name="accept"
                value="Accept"
                v-model="v$.accept.$model"
                :class="{ 'p-invalid': v$.accept.$invalid && submitted }"
              />
              <label
                for="accept"
                :class="{ 'p-error': v$.accept.$invalid && submitted }"
                >I agree to the terms and conditions*</label
              >
            </div>
            <Button type="submit" label="Submit" class="mt-2" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Password from "primevue/password";
import SplitButton from "primevue/splitbutton";
import { reactive, ref } from "vue";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Home",
  setup() {
    const state = reactive({
      name: "",
      email: "",
      password: "",
      accept: null,
      message: null,
      text: null,
    });

    const rules = {
      name: { required },
      email: { required, email },
      password: { required },
      accept: { required },
    };

    const submitted = ref(false);
    const showMessage = ref(false);
    const date = ref();

    const v$ = useVuelidate(rules, state);

    const handleSubmit = (isFormValid) => {
      submitted.value = true;

      if (!isFormValid) {
        return;
      }

      toggleDialog();
    };
    const toggleDialog = () => {
      showMessage.value = !showMessage.value;

      if (!showMessage.value) {
        resetForm();
      }
    };
    const resetForm = () => {
      state.name = "";
      state.email = "";
      state.password = "";
      state.date = null;
      state.accept = null;
      submitted.value = false;
    };

    const greet = () => {
      this.$toast.add({ severity: "info", summary: "Hello " + this.text });
      this.message = "Hello " + this.text;
    };

    return {
      state,
      v$,
      handleSubmit,
      toggleDialog,
      submitted,
      showMessage,
      date,
      greet,
    };
  },
  components: {
    HelloWorld,
    InputText,
    Button,
    SplitButton,
    Checkbox,
    Calendar,
    Password,
  },
};
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px;
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }
}
.p-splitbutton {
  margin-right: 0.5rem;
}
</style>
